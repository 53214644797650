<template>
    <div>
        <webix-ui :config="ui" v-model="orders"></webix-ui>
        <div id="ordersPaging"></div>
    </div>
</template>

<script>
import { orderMethods, orderComputed, adminComputed } from "@/state/helpers";
//eslint-disable-next-line
import { DateTime } from "luxon";
import Swal from "sweetalert2";

export default {
    data: () => ({
        ui: {
            id: "ordersTable",
            view: "datatable",
            css: "datatableUsers webix_header_border webix_data_border",
            autoheight: true,
            //hover: "datatableHover",
            minHeight: 60,
            minColumnWidth: 120,
            rowHeight: 40,
            scrollY: false,
            scrollX: true,
            resizeColumn: true,
            select: false,
            pager: {
                template: "{common.prev()} {common.pages()} {common.next()}",
                container: "ordersPaging",
                css: { width: "100% !important" },
                size: 15,
                group: 5,
            },
            columns: [
                {
                    id: "actions",
                    header: "Actions",
                    adjust: true,
                    //eslint-disable-next-line
                    template: function (value) {
                        //eslint-disable-next-line
                        // console.log("the value is", value);
                        return '<div class="tableIcons"><i class="mdi mdi-trash-can deleteAction"></i>&nbsp;<i class="mdi mdi-eye viewAction"></i></div>';
                    },
                },
                {
                    id: "order_no",
                    header: ["Order Number", { content: "textFilter" }],
                    tooltip: false,
                    adjust: true,
                },
                {
                    id: "due_date",
                    header: ["Due Date", { content: "textFilter" }],
                    adjust: true,

                    //eslint-disable-next-line
                    cssFormat: function (value, config) {
                        const DateNow = new Date().toISOString();
                        const dueDate = new Date(value).toISOString();

                        //luxon conversion
                        const luxonDateNow = DateTime.fromISO(DateNow);
                        const luxonDueDate = DateTime.fromISO(dueDate);

                        //eslint-disable-next-line
                        const diff = luxonDueDate.diff(luxonDateNow, ["years", "months", "days", "hours"]);

                        if (diff.days == 1 && diff.hours >= 1) return "bg-warning text-light";

                        if (
                            //prettier-ignore
                            ((diff.days == 0 || diff.days < 0) || (diff.days == 0 && diff.hours >= 1)) &&
                            config.status_name != "Dispatched"
                        )
                            return "bg-danger text-light";

                        return;
                    },
                },
                {
                    id: "dispatched_date",
                    header: ["Dispatched Date", { content: "textFilter" }],
                    tooltip: false,
                    adjust: true,
                },
                {
                    id: "status_name",
                    header: ["Status", { content: "selectFilter" }],
                    adjust: true,
                },
                {
                    id: "order_branch_name",
                    header: ["Branch", { content: "selectFilter" }],
                    tooltip: false,
                    adjust: true,
                },
                {
                    id: "client_name",
                    header: ["Client Name", { content: "textFilter" }],
                    adjust: true,
                },
                {
                    id: "contact_email",
                    header: ["Email", { content: "textFilter" }],
                    adjust: true,
                },
                {
                    id: "user_name",
                    header: ["Created By", { content: "textFilter" }],
                    adjust: true,
                },
                {
                    id: "user_email",
                    header: ["Created By Email", { content: "textFilter" }],
                    adjust: true,
                },
                {
                    id: "created_date",
                    header: ["Created Date", { content: "textFilter" }],
                    tooltip: false,
                    adjust: true,
                },
            ],
            on: {},
            onClick: {
                //eslint-disable-next-line
                viewAction: function (id, e, trg) {
                    // var el = this.$scope.$parent;
                    // el.setSelectedOrder(e.row);
                    let el = this.$scope.$parent;
                    //eslint-disable-next-line
                    let idForUrl = e.row;

                    el.setSelectedOrder(e.row);

                    el.$router.push({
                        path: `/orders/view/order/${idForUrl}`,
                        //param: { idForUrl },
                    });
                },
                //eslint-disable-next-line
                deleteAction: function (id, e, trg) {
                    let item = this.getItem(e.row);
                    let el = this.$scope.$parent;
                    Swal.fire({
                        title: `Are you sure you want to delete order
                            "${item.order_no}" ?`,
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Yes, delete it!",
                    }).then((result) => {
                        if (result.value) {
                            el.ready = false;
                            el.deleteOrder({ order_id: e.row }).then(() => {
                                // el.getStockists().then(() => {
                                //     el.ready = true;
                                // });
                                Swal.fire("Order was deleted", "", "success");

                                //refresh the table on the screen
                                el.getBranchOrders(-1).then(() => {
                                    el.ready = true;
                                });
                            });
                        }
                    });
                },
            },
        },
    }),
    watch: {
        // selected_branch_id() {
        //     // this.getBranchOrders(this.selected_branch_id).then(() => {
        //     //     //this.ready = true;
        //     // });
        //     this.getBranchOrders(-1).then(() => {
        //         //this.ready = true;
        //     });
        // },
    },
    computed: {
        ...adminComputed,
        ...orderComputed,
        orders() {
            return this.branch_orders;
        },
    },
    methods: {
        ...orderMethods,
    },
    mounted() {
        // if (this.branch_orders.length == 0) {
        //     this.getBranchOrders(this.selected_branch_id).then(() => {
        //         //this.ready = true;
        //     });
        // }
        // if (this.branch_orders.length == 0) {
        //     //eslint-disable-next-line
        //     console.log("this is why");
        //     this.getBranchOrders(-1).then(() => {
        //         //this.ready = true;
        //     });
        // }
    },
};
</script>

<style>
.make-red {
    background-color: red;
    color: white;
}
.make-orange {
    background-color: orange;
    color: white;
}
</style>
